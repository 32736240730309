<template>
  <div class="payment-info">
    <v-data-table
      :options.sync="options"
      :headers="headers"
      :items="employeeList"
      class="table-employee table-sticky-medical"
      :mobile-breakpoint="0"
      hide-default-footer
      :loading="myloadingvariable"
      :items-per-page="-1"
      :custom-sort="customSort"
    >
      <template v-slot:top>
        <v-row class="d-flex flex align-center justify-space-between px-0 py-0">
          <v-col cols="12" md="12" class="pb-2">
            <v-toolbar-title class="package-title text-capitalize pl-2"
              >{{ $t("page.profile.examination_history") }} {{ $t("company") }}
            </v-toolbar-title>
          </v-col>
          <v-col
            cols="12"
            md="12"
            class="d-flex justify-end align-center pr-0 pt-1 pb-0"
          >
            <v-select
              v-model="resultVal"
              :items="listResultPar"
              item-text="name"
              item-value="id"
              dense
              @change="selectedResultPar(resultVal)"
              :label="$t('select_result_par')"
              outlined
              class="pl-3"
              hide-details
            />
            <v-select
              v-model="statusApproval"
              :items="listStatusApprove"
              item-text="title"
              item-value="id"
              dense
              @change="selectedStatusApprove(statusApproval)"
              :label="$t('select_status')"
              outlined
              class="pl-3"
              hide-details
            />
          </v-col>
          <v-col
            cols="12"
            md="12"
            class="d-flex justify-end align-center pr-0 pt-1"
          >
            <v-select
              v-model="jobPostVal"
              :items="listJobPost"
              item-text="title"
              item-value="id"
              dense
              @change="selectedJobPost(jobPostVal)"
              :label="$t('select_job_post')"
              outlined
              class="pl-3"
              hide-details
            />
            <v-select
              v-model="deptVal"
              :items="listDepartment"
              item-text="title"
              item-value="id"
              dense
              @change="selectedDepartment(deptVal)"
              :label="$t('select_department')"
              outlined
              class="pl-3"
              hide-details
            />
            <v-text-field
              class="searchField fontSet pl-3 pr-3"
              outlined
              dense
              hide-details
              v-model="search"
              append-icon="mdi-magnify"
              :label="$t('search') + ' ...'"
            ></v-text-field>

            <div>
              <v-select
                class="footerHide"
                v-model="perPage"
                :items="itemShowing"
              ></v-select>
            </div>
          </v-col>
        </v-row>
      </template>
      <template v-slot:item="{ item }">
        <tr class="hd-mr-sticky">
          <td valign="top" class="pt-2 sticky-cmr1">
            {{
              item.registration_group !== null ? item.registration_group : "-"
            }}
          </td>
          <td valign="top" class="pt-2 sticky-cmr2">
            {{
              item.booking_date !== null ? formatDate(item.booking_date) : "-"
            }}
          </td>
          <td valign="top" class="pt-2">
            {{ item.employee_number !== null ? item.employee_number : "-" }}
          </td>
          <td valign="top" class="pt-2 tb-wide">
            <template v-if="item.order_number !== null">
              <template v-if="item.status == 'COMPLETED'">
                <a href="javascript:void(0);" @click="openDialogOrder(item)">{{
                  item.order_number
                }}</a>
              </template>
              <template v-else>
                {{ item.order_number }}
              </template>
            </template>
            <template v-else> - </template>
          </td>
          <td valign="top" class="pt-2">
            {{
              item.final_result_name !== null
                ? item.final_result_name.toUpperCase()
                : "-"
            }}
          </td>
          <td valign="top" class="pt-2">
            {{ item.medical_record_number }}
          </td>
          <td valign="top" class="pt-2">{{ item.patient }}</td>

          <td valign="top" class="pt-2 uppercase-input">
            {{
              item.department !== null
                ? $fnc.replaceSpecialChars(item.department).toUpperCase()
                : "-"
            }}
          </td>
          <td valign="top" class="pt-2 uppercase-input">
            {{
              item.job_position !== null ? item.job_position.toUpperCase() : "-"
            }}
          </td>
          <td valign="top" class="pt-2">{{ item.status }}</td>
        </tr>
      </template>

      <template v-slot:footer>
        <v-row
          class="d-flex flex align-center justify-space-between footer-payment"
        >
          <v-col cols="12" md="8">
            <p class="footer-showing">
              showing {{ itemShowStart }} to {{ itemShowStop }} of
              {{ itemsTotal }} entries
            </p>
          </v-col>
          <v-col cols="12" md="4" class="d-flex justify-end">
            <custom-pagination
              @changePage="val => (page = val)"
              :totalPage="itemLength"
              :activePage="page"
            />
          </v-col>
        </v-row>
      </template>
    </v-data-table>

    <DialogOrderDetail
      v-if="dialog_order"
      :item="dataDialogOrder"
      :tampilkan="dialog_order"
      @toggleDialog="is_show => toggleDialogOrder(is_show)"
    />
  </div>
</template>

<script>
// import TabContent from "@/components/v2/tabs/index.vue";
import API from "@/service/api_service.js";
import Auth from "@/service/auth_service";
import moment from "moment";
import DialogOrderDetail from "@/components/v3/results/index.vue";
import CustomPagination from "@/components/v3/pagination.vue";

export default {
  components: { DialogOrderDetail, CustomPagination },
  data: () => ({
    page: 1,
    options: null,
    itemsTotal: 0,
    itemLength: 1,
    itemShowStart: 0,
    itemShowStop: 0,
    itemShowing: [
      { value: 25, text: "25" },
      { value: 50, text: "50" },
      { value: 70, text: "70" },
      { value: 100, text: "100" },
    ],
    perPage: 25,
    myloadingvariable: true,
    myDepoHistoryListCount: 0,
    showNavbar: true,
    showCart: false,
    showCartDesktop: false,
    showSearch: false,
    showLogo: true,
    search: "",
    dialogDelete: false,
    formHasError: false,
    headers: [],
    employeeList: [],

    checkItemMonth: false,
    checkItemYear: false,
    modalStartDate: false,
    modalEndDate: false,
    start_date: "",
    end_date: "",
    start_date_filter: "",
    end_date_filter: "",
    now: "",
    dialog_order: false,
    statusApproval: "",
    listStatusApprove: [],
    listResultPar: [],
    jobPostVal: "",
    listJobPost: [],
    deptVal: "",
    resultVal: "",
    listDepartment: [],
    dataDialogOrder: {
      order_id: null,
      list: [
        {
          document_information: {
            number_of_report: null,
            medical_record_number: null,
            datetime_observation: null,
            gender: null,
            plant: null,
            primary_doctor: null,
            lab_chief: null,
          },
          customers_information: {
            patient_name: null,
            address: null,
            city: null,
            province: null,
            postal_code: null,
            country: null,
            dob: null,
            patient_age: null,
          },
          product: {
            product_id: null,
            product_code: null,
            product_name: null,
          },
        },
      ],
    },
  }),
  computed: {
    dateFormatedStart() {
      return this.formatDateNow(this.start_date);
    },
    dateFormatedEnd() {
      return this.formatDateNow(this.end_date);
    },
  },
  async mounted() {
    this.headers = [
      {
        text: this.$t("page.profile.booking_batch"),
        value: "registration_group",
        align: "center",
        class: "sticky-hmr1",
        width: "159px",
        sortable: false,
      },
      {
        text: this.$t("page.profile.booking_date"),
        value: "booking_date",
        align: "center",
        class: "sticky-hmr2",
        sortable: true,
      },
      {
        text: this.$t("field.employee_number"),
        value: "employee_number",
        align: "center",
        sortable: false,
      },
      {
        text: this.$t("page.profile.order_no"),
        value: "order_number",
        sortable: false,
        align: "center",
      },
      {
        text: this.$t("final_result"),
        value: "final_result",
        sortable: false,
        align: "center",
      },
      {
        text: this.$t("page.profile.patient_id"),
        value: "medical_record_number",
        sortable: false,
        align: "center",
      },
      {
        text: this.$t("page.profile.patient_name"),
        value: "patient",
        sortable: true,
        align: "center",
      },

      {
        text: this.$t("field.department"),
        value: "department",
        sortable: false,
        align: "center",
      },
      {
        text: this.$t("field.jobpost"),
        value: "job_position",
        sortable: false,
        align: "center",
      },
      {
        text: this.$t("page.profile.status"),
        value: "status",
        align: "center",
      },
      //   {
      //     text: this.$t("page.profile.result_notes"),
      //     value: "result_notes",
      //     sortable: false,
      //     align: "left",
      //     width: "120px",
      //   },
      //   {
      //     text: this.$t("page.profile.invoice_amount"),
      //     value: "amount_total",
      //     sortable: false,
      //     align: "right",
      //     width: "150px",
      //   },
    ];

    // filter result
    API.get(`${process.env.VUE_APP_API_TRUST}mcu-final-result/get`, {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth.getToken()}`,
    }).then(resResult => {
      console.log("qwertyu", resResult);
      if (resResult.statusCode == 200) {
        // const checkDataResult = resResult.results.map(e => e.status);
        this.listResultPar = resResult.results;
      }
    });

    // filter status
    API.get(`${process.env.VUE_APP_API_TRUST}employee/get-booking-status`, {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth.getToken()}`,
    }).then(resStatus => {
      if (resStatus.statusCode == 200) {
        const checkDataStatus = resStatus.results.map(e => e.status);
        this.listStatusApprove = checkDataStatus;
      }
    });

    // filter department
    API.get(
      `${process.env.VUE_APP_API_TRUST}patient-insurance/get-department`,
      {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Auth.getToken()}`,
      }
    ).then(resDep => {
      if (resDep.statusCode == 200) {
        const checkDataDept = resDep.results.map(e => e.department);
        this.listDepartment = checkDataDept;
      }
    });

    // filter job position
    API.get(`${process.env.VUE_APP_API_TRUST}job-position/get`, {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth.getToken()}`,
    }).then(resJob => {
      if (resJob.statusCode == 200) {
        const checkDataJobPost = resJob.results.map(e => e.name);
        this.listJobPost = checkDataJobPost;
      }
    });
  },
  watch: {
    page: function () {
      this.getDataDeposits();
    },
    perPage: function () {
      if (this.page != 1) {
        this.page = 1;
      } else {
        this.getDataDeposits();
      }
    },
    search: function () {
      this.page = 1;
      this.getDataDeposits();
    },
  },
  created() {
    this.getDataDeposits();
  },
  methods: {
    customSort(items, index, isDesc) {
      items.sort((a, b) => {
        let result = 0;

        for (let i = 0; i < index.length; i++) {
          let key = index[i];
          let desc = isDesc[i];

          if (key === "booking_date") {
            let dateA = new Date(a[key]);
            let dateB = new Date(b[key]);

            if (dateA < dateB) result = desc ? 1 : -1;
            else if (dateA > dateB) result = desc ? -1 : 1;
          } else {
            let valueA =
              a[key] !== undefined ? a[key].toString().toLowerCase() : "";
            let valueB =
              b[key] !== undefined ? b[key].toString().toLowerCase() : "";

            if (valueA < valueB) result = desc ? 1 : -1;
            else if (valueA > valueB) result = desc ? -1 : 1;
          }

          if (result !== 0) break;
        }

        return result;
      });

      return items;
    },
    async getDataDeposits() {
      try {
        this.myloadingvariable = true;
        // pagination showing start of entries
        let startPage = (this.page - 1) * this.perPage;
        let showPage = this.perPage;
        this.itemShowStart = startPage + 1;
        this.itemShowStop = startPage + showPage;

        const res = await API.get(
          `${process.env.VUE_APP_API_TRUST}employee/get-booking-detail?query=${this.search}&offset=${startPage}&per_page=${showPage}&department=${this.deptVal}&job_position=${this.jobPostVal}&status=${this.statusApproval}&final_result_id=${this.resultVal}`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );
        if (res.statusCode == 200) {
          // var checkData = res.results.filter((res) => {
          //   return res.registration_group != null;
          // });

          // this.employeeList = checkData;
          this.employeeList = res.results;
        }

        this.now = this.formatDateNow(new Date());
        this.start_date = new Date(
          `${new Date().getFullYear()}-${
            new Date().getMonth() + 1
          }-${new Date().getDate()}`
        )
          .toISOString()
          .substr(0, 10);
        this.end_date = new Date().toISOString().substr(0, 10);

        this.itemsTotal = res.total;
        this.itemLength = Math.ceil(this.itemsTotal / showPage);
        if (this.itemShowStop < 0) {
          this.itemShowStop = res.total;
        }
        if (this.itemShowStop > res.total) {
          this.itemShowStop = res.total;
        }
        this.myloadingvariable = false;
      } catch (error) {
        console.log(error);
      }
    },
    setPageMyDepoHistory(data) {
      this.myDepoHistoryListCount = data.pageStop - data.pageStart;
    },
    toggleDialogOrder(toggle) {
      this.dialog_order = toggle;
    },
    downloadLab(param, type, isProduct) {
      if (isProduct) {
        API.get(
          `${process.env.VUE_APP_API_TRUST}diagnosis/get-labreport?order_number=${param}&type=${type}`,
          {
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        ).then(res => {
          this.download = res;
          window.open(this.download.linkDownload, "_blank");
        });
      } else {
        API.get(
          `${process.env.VUE_APP_API_TRUST}diagnosis/get-mcureport?order_number=${param}&type=${type}`,
          {
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        ).then(res => {
          this.download = res;
          window.open(this.download.linkDownload, "_blank");
        });
      }
    },
    // downloadLab(param) {
    //   API.get(
    //     `${process.env.VUE_APP_API_TRUST}diagnosis/get-labreport?order_number=${param}`,
    //     {
    //       Authorization: `Bearer ${Auth.getToken()}`,
    //     }
    //   ).then(res => {
    //     this.download = res;
    //     window.open(this.download.linkDownload, "_blank");
    //   });
    // },
    // downloadMcu(param) {
    //   API.get(
    //     `${process.env.VUE_APP_API_TRUST}diagnosis/get-mcureport?order_number=${param}`,
    //     {
    //       Authorization: `Bearer ${Auth.getToken()}`,
    //     }
    //   ).then(res => {
    //     this.download = res;
    //     window.open(this.download.linkDownload, "_blank");
    //   });
    // },
    formatDate(date) {
      return moment(date)
        .locale(this.$i18n.locale)
        .format("DD-MMM-YYYY")
        .toUpperCase();
    },
    formatPrice(price) {
      return Intl.NumberFormat().format(price);
    },
    // sum(key) {
    //   return this.paymentList.length > 0
    //     ? this.paymentList.reduce(
    //         (a, b) => parseFloat(a) + (parseFloat(b[key]) || 0),
    //         0
    //       )
    //     : 0;
    // },
    formatDateNow(param) {
      if (!param) return null;
      return moment(param)
        .locale(this.$i18n.locale)
        .format("DD-MMM-YYYY")
        .toUpperCase();
    },
    changeFilterDate() {
      this.$refs.dialog.save(this.start_date);
      this.$refs.dialogend.save(this.end_date);
      this.getDateRange();
      this.modalStartDate = false;
      this.modalEndDate = false;
    },
    getDateRange() {
      this.checkItemYear = false;
      this.checkItemMonth = false;

      this.myloadingvariable = true;
      // pagination showing start of entries
      let startPage = (this.page - 1) * this.perPage;
      let showPage = this.perPage;
      this.itemShowStart = startPage + 1;
      this.itemShowStop = startPage + showPage;

      API.get(
        `${process.env.VUE_APP_API_TRUST}employee/get-booking-detail?offset=${startPage}&per_page=${showPage}`,
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Auth.getToken()}`,
        }
      ).then(res => {
        this.start_date_filter = new Date(this.start_date).getTime();
        this.end_date_filter = new Date(this.end_date).getTime();

        var checkData = res.results.filter(res => {
          return res.registration_group != null;
        });

        const dateRangeFilter = checkData.filter(dateRangeData => {
          const newDateRangeData = new Date(dateRangeData.booking_date);
          const setDateRange = `${newDateRangeData.getFullYear()}-${(
            "0" +
            (newDateRangeData.getMonth() + 1)
          ).slice(-2)}-${("0" + newDateRangeData.getDate()).slice(-2)}`;
          const dateRangeGetTime = newDateRangeData.getTime();

          if (setDateRange == this.start_date) {
            const setNewDateStart = new Date(this.start_date);
            const setStartFilter = `${setNewDateStart.getFullYear()}-${
              setNewDateStart.getMonth() + 1
            }-${setNewDateStart.getDate() - 1}`;
            const startFilterGetTime = new Date(setStartFilter).getTime();

            return dateRangeGetTime > startFilterGetTime;
          } else if (setDateRange == this.end_date) {
            const setDateFilterRange = `${newDateRangeData.getFullYear()}-${
              newDateRangeData.getMonth() + 1
            }-${newDateRangeData.getDate() - 1}`;
            const DateNewDateFilter = new Date(setDateFilterRange).getTime();
            const endFilterGetTime = new Date(this.end_date).getTime();

            return DateNewDateFilter < endFilterGetTime;
          } else {
            return (
              dateRangeGetTime > this.start_date_filter &&
              dateRangeGetTime < this.end_date_filter
            );
          }
        });
        this.employeeList = dateRangeFilter;

        this.itemsTotal = res.total;
        this.itemLength = Math.ceil(this.itemsTotal / showPage);
        if (this.itemShowStop < 0) {
          this.itemShowStop = res.total;
        }
        if (this.itemShowStop > res.total) {
          this.itemShowStop = res.total;
        }
        this.myloadingvariable = false;
      });
    },
    getYearClick() {
      const dateNow = new Date();
      const setYearNow = `${dateNow.getFullYear()}`;

      this.myloadingvariable = true;
      // pagination showing start of entries
      let startPage = (this.page - 1) * this.perPage;
      let showPage = this.perPage;
      this.itemShowStart = startPage + 1;
      this.itemShowStop = startPage + showPage;

      API.get(
        `${process.env.VUE_APP_API_TRUST}employee/get-booking-detail?offset=${startPage}&per_page=${showPage}`,
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Auth.getToken()}`,
        }
      ).then(res => {
        var checkData = res.results.filter(res => {
          return res.registration_group != null;
        });
        const yearFilter = checkData.filter(setDataYear => {
          const newYearFilterData = new Date(setDataYear.booking_date);
          const setDateFormat = `${(
            "0" +
            (newYearFilterData.getMonth() + 1)
          ).slice(-2)}/${("0" + newYearFilterData.getDate()).slice(
            -2
          )}/${newYearFilterData.getFullYear()}`;
          const yearSlice = +setDateFormat.slice(-4);
          return setYearNow == yearSlice;
        });

        this.employeeList = yearFilter;
        this.checkItemYear = true;
        this.checkItemMonth = false;

        this.itemsTotal = res.total;
        this.itemLength = Math.ceil(this.itemsTotal / showPage);
        if (this.itemShowStop < 0) {
          this.itemShowStop = res.total;
        }
        if (this.itemShowStop > res.total) {
          this.itemShowStop = res.total;
        }
        this.myloadingvariable = false;
      });
    },
    getMonthClick() {
      const dateNow = new Date();
      const setMonthYearNow = `${("0" + (dateNow.getMonth() + 1)).slice(
        -2
      )}-${dateNow.getFullYear()}`;

      this.myloadingvariable = true;
      // pagination showing start of entries
      let startPage = (this.page - 1) * this.perPage;
      let showPage = this.perPage;
      this.itemShowStart = startPage + 1;
      this.itemShowStop = startPage + showPage;

      API.get(
        `${process.env.VUE_APP_API_TRUST}employee/get-booking-detail?offset=${startPage}&per_page=${showPage}`,
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Auth.getToken()}`,
        }
      ).then(res => {
        var checkData = res.results.filter(res => {
          return res.registration_group != null;
        });
        const monthYearFilter = checkData.filter(setDataMonthYear => {
          const newMonthYearFilterData = new Date(
            setDataMonthYear.booking_date
          );
          const setMonthYearFormat = `${(
            "0" +
            (newMonthYearFilterData.getMonth() + 1)
          ).slice(-2)}-${newMonthYearFilterData.getFullYear()}`;

          return setMonthYearNow == setMonthYearFormat;
        });

        this.employeeList = monthYearFilter;
        this.checkItemYear = false;
        this.checkItemMonth = true;

        this.itemsTotal = res.total;
        this.itemLength = Math.ceil(this.itemsTotal / showPage);
        if (this.itemShowStop < 0) {
          this.itemShowStop = res.total;
        }
        if (this.itemShowStop > res.total) {
          this.itemShowStop = res.total;
        }
        this.myloadingvariable = false;
      });
    },
    sum(key) {
      return this.employeeList.length > 0
        ? this.employeeList.reduce(
            (a, b) => parseFloat(a) + (parseFloat(b[key]) || 0),
            0
          )
        : 0;
    },
    async openDialogOrder(item) {
      this.dataDialogOrder.order_number = item.order_number;
      this.dataDialogOrder.type_test = item.type;
      this.getOrderDetailPopup();
    },
    async getOrderDetailPopup() {
      try {
        const res = await API.get(
          `${process.env.VUE_APP_API_TRUST}medical-record/inspection/list?order_number=${this.dataDialogOrder.order_number}`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );
        if (res.statusCode === 200) {
          this.dataDialogOrder.results = res.results;
          this.dialog_order = true;
        } else if (res.statusCode == 400) {
          this.$swal({
            icon: "error",
            text: res.message,
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async selectedResultPar(param) {
      try {
        this.myloadingvariable = true;
        // pagination showing start of entries
        let startPage = (this.page - 1) * this.perPage;
        let showPage = this.perPage;
        this.itemShowStart = startPage + 1;
        this.itemShowStop = startPage + showPage;

        const res = await API.get(
          `${process.env.VUE_APP_API_TRUST}employee/get-booking-detail?query=${this.search}&offset=${startPage}&per_page=${showPage}&department=${this.deptVal}&job_position=${this.jobPostVal}&status=${this.statusApproval}&final_result_id=${param}`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );

        if (res.statusCode == 200) {
          this.employeeList = res.results;

          this.itemsTotal = res.total;
          this.itemLength = Math.ceil(this.itemsTotal / showPage);
          if (this.itemShowStop < 0) {
            this.itemShowStop = res.total;
          }
          if (this.itemShowStop > res.total) {
            this.itemShowStop = res.total;
          }
          // }
        } else {
          console.log("No data found");
          this.employeeList = [];
          this.itemsTotal = 0;
          this.itemLength = 0;
          this.itemShowStop = 0;
        }
        this.myloadingvariable = false;
      } catch (error) {
        console.log(error);
        this.employeeList = [];
        this.itemsTotal = 0;
        this.itemLength = 0;
        this.itemShowStop = 0;
      }
    },
    async selectedStatusApprove(param) {
      try {
        this.myloadingvariable = true;
        // pagination showing start of entries
        let startPage = (this.page - 1) * this.perPage;
        let showPage = this.perPage;
        this.itemShowStart = startPage + 1;
        this.itemShowStop = startPage + showPage;

        const res = await API.get(
          `${process.env.VUE_APP_API_TRUST}employee/get-booking-detail?query=${this.search}&offset=${startPage}&per_page=${showPage}&department=${this.deptVal}&job_position=${this.jobPostVal}&status=${param}&final_result_id=${this.resultVal}`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );

        if (res.statusCode == 200) {
          this.employeeList = res.results;

          this.itemsTotal = res.total;
          this.itemLength = Math.ceil(this.itemsTotal / showPage);
          if (this.itemShowStop < 0) {
            this.itemShowStop = res.total;
          }
          if (this.itemShowStop > res.total) {
            this.itemShowStop = res.total;
          }
          // }
        } else {
          console.log("No data found");
          this.employeeList = [];
          this.itemsTotal = 0;
          this.itemLength = 0;
          this.itemShowStop = 0;
        }
        this.myloadingvariable = false;
      } catch (error) {
        console.log(error);
        this.employeeList = [];
        this.itemsTotal = 0;
        this.itemLength = 0;
        this.itemShowStop = 0;
      }
    },

    async selectedJobPost(param) {
      try {
        this.myloadingvariable = true;
        // pagination showing start of entries
        let startPage = (this.page - 1) * this.perPage;
        let showPage = this.perPage;
        this.itemShowStart = startPage + 1;
        this.itemShowStop = startPage + showPage;

        const res = await API.get(
          `${process.env.VUE_APP_API_TRUST}employee/get-booking-detail?query=${this.search}&offset=${startPage}&per_page=${showPage}&department=${this.deptVal}&job_position=${param}&status=${this.statusApproval}&final_result_id=${this.resultVal}`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );

        if (res.statusCode == 200) {
          this.employeeList = res.results;

          this.itemsTotal = res.total;
          this.itemLength = Math.ceil(this.itemsTotal / showPage);
          if (this.itemShowStop < 0) {
            this.itemShowStop = res.total;
          }
          if (this.itemShowStop > res.total) {
            this.itemShowStop = res.total;
          }
          // }
        } else {
          console.log("No data found");
          this.employeeList = [];
          this.itemsTotal = 0;
          this.itemLength = 0;
          this.itemShowStop = 0;
        }
        this.myloadingvariable = false;
      } catch (error) {
        console.log(error);
        this.employeeList = [];
        this.itemsTotal = 0;
        this.itemLength = 0;
        this.itemShowStop = 0;
        this.myloadingvariable = false;
      }
    },
    async selectedDepartment(param) {
      try {
        this.myloadingvariable = true;
        // pagination showing start of entries
        let startPage = (this.page - 1) * this.perPage;
        let showPage = this.perPage;
        this.itemShowStart = startPage + 1;
        this.itemShowStop = startPage + showPage;

        const res = await API.get(
          `${process.env.VUE_APP_API_TRUST}employee/get-booking-detail?query=${this.search}&offset=${startPage}&per_page=${showPage}&department=${param}&job_position=${this.jobPostVal}&status=${this.statusApproval}&final_result_id=${this.resultVal}`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );

        if (res.statusCode == 200) {
          this.employeeList = res.results;
          this.itemsTotal = res.total;
          this.itemLength = Math.ceil(this.itemsTotal / showPage);
          if (this.itemShowStop < 0) {
            this.itemShowStop = res.total;
          }
          if (this.itemShowStop > res.total) {
            this.itemShowStop = res.total;
          }
          // }
        } else if (res.statusCode == 404) {
          console.log("No data found");
          this.employeeList = [];
          this.itemsTotal = 0;
          this.itemLength = 0;
          this.itemShowStop = 0;
        }
        this.myloadingvariable = false;
      } catch (error) {
        console.log(error);
        this.employeeList = [];
        this.itemsTotal = 0;
        this.itemLength = 0;
        this.itemShowStop = 0;
        this.myloadingvariable = false;
      }
    },
  },
};
</script>
<style lang="scss">
@media screen and (min-width: 1023px) {
  .table-sticky-medical {
    &.theme--light.v-data-table {
      .v-data-table__wrapper {
        table {
          thead {
            z-index: 3 !important;
            tr {
              th {
                position: sticky;
                top: 0;
                z-index: 2;
                background: #ddd !important;

                &:nth-child(1) {
                  position: sticky !important;
                  position: -webkit-sticky !important;
                  left: 0;
                  z-index: 5;
                }
                &:nth-child(2) {
                  position: sticky !important;
                  position: -webkit-sticky !important;
                  left: 159px;
                  z-index: 5;
                }
              }
            }
          }
          tbody {
            tr {
              &.hd-mr-sticky:hover {
                .sticky-cmr1 {
                  background: #eee !important;
                }
                .sticky-cmr2 {
                  background: #eee !important;
                }
              }
              td {
                &:nth-child(1) {
                  position: sticky !important;
                  position: -webkit-sticky !important;
                  left: 0;
                  z-index: 2;
                  background: #fff;
                }
                &:nth-child(2) {
                  position: sticky !important;
                  position: -webkit-sticky !important;
                  left: 159px;
                  z-index: 2;
                  background: #fff;
                }
              }
            }
          }
        }
      }
    }
  }
}

.table-employee thead {
  background: #ddd !important;
  font-weight: bold;
  color: #000 !important;
}
.table-employee thead tr th {
  height: 38px !important;
  white-space: nowrap;
}
.table-employee tbody tr td {
  font-size: 12px !important;
  height: 35px !important;
  white-space: nowrap;
}
.table-employee tbody tr:hover {
  background-color: #eee !important;
  z-index: 999999;
}

.uppercase-input {
  text-transform: uppercase;
}
</style>
<style lang="scss" scoped>
@import "@/assets/css/custom.scss";

.package-title {
  font-size: 16px;
  font-weight: bold;
  color: #233f78;
  margin-bottom: 0;
}
.searchField {
  max-width: 500px;
}
.button--active {
  background: $main_3;
  color: white;
}
.button--outline {
  color: $main_3;
  border: 1px solid $main_3;
  background: white;
}
.button--download {
  background: #79c4c6;
  color: white;
}
.button-box {
  box-sizing: border-box;
  border-radius: 10px;
  padding: 8px 16px;
  outline: none;
}
.card--result {
  background: $gray_3;
  padding: 18px;
  border-radius: 16px;
  width: 100%;
  // max-width: 300px;
  color: $gray_2;
  h4 {
    color: $gray_1;
    font-size: 20px;
  }
  p {
    margin: 0;
  }
  .card--result__item {
    padding: 6px 0;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $gray_2;
    &:nth-last-child(1) {
      border: none;
    }
  }
}

.status--inprogress {
  color: $yellow_1;
  font-weight: 600;
}
.status--complete {
  color: $green_1;
  font-weight: 600;
}
.status--cancel {
  color: $red_1;
  font-weight: 600;
}
.tabs {
  background: #e5e5e5 !important;
}
.content-table {
  padding: 15px;
  @media screen and (max-width: 960px) {
    padding: 5px 18px 18px 18px;
  }
}
@media screen and (max-width: 480px) {
  .content-table {
    padding: 0px !important;
  }
}
td {
  font-size: 12px !important;
  vertical-align: top !important;
}
.fontSet {
  font-size: 14px !important;
}
.button {
  background-color: #00a4b3 !important;
  border-radius: 5px;
  color: white !important;
  font-size: 14px;
  text-align: center;
  text-transform: capitalize;
  height: 30px !important;
  min-width: 100px !important;
}
a {
  text-decoration: none;
}
.fontSet {
  font-size: 14px !important;
}
.box-page-new {
  margin: 20px;
  border-radius: 4px !important;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 12px;
  vertical-align: baseline;
  padding-top: 6px;
  padding-bottom: 6px;
  height: 25px !important;
}

.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  height: 35px !important;
}
</style>
<style lang="scss">
.footerHide {
  background: none !important;
  align-items: center;
  width: 50px;
  font-size: 12px;
}
.footerHide > .v-data-footer__pagination {
  display: none !important;
}
.footerHide > .v-data-footer__icons-before {
  display: none !important;
}
.footerHide > .v-data-footer__icons-after {
  display: none !important;
}
.payment-info .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 12px;
  vertical-align: baseline;
  padding-top: 6px;
  padding-bottom: 6px;
}
.v-toolbar__title {
  font-size: 16px;
}
.footer-payment {
  margin: 0 auto !important;
}
.footer-showing {
  font-size: 12px !important;
  margin: 0 auto !important;
}

.footerHide > .v-text-field .v-input__prepend-inner,
.v-text-field .v-input__append-inner {
  padding-left: 0 !important;
}
</style>
